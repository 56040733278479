import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";

import fire from '../../../fire';

import './Leaderboard.scss';

import BtnLeaderboard from '../../../Images/Icons/btn_leaderboard-alt.png';
import BtnGame from '../../../Images/Icons/btn_game.png';
import BtnRefresh from '../../../Images/Icons/btn_refresh.png';

export default function Leaderboard (props) {
    let [ users, setUsers ] = useState([]);
    let { showId } = useParams();

    useEffect(() => {
        setLeaderboard();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setLeaderboard = () => {
        getUsers();
    }

    const getUsers = async () => {
        let list = [];
        let docRef = fire.firestore().collection("shows").doc(showId).collection("users");

        if (showId === "NOSHOW") {
            docRef = fire.firestore().collection("shows").doc(showId).collection("users").orderBy("created", "desc").limit(50);
        }

        await docRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let obj = {};
                obj.name = doc.data().name;
                obj.score = doc.data().score;
                list.push(obj)
            });
        })
        list.sort((a,b) => {
            return b.score - a.score;
        })
        setUsers([...list]);
    }

    const handleClick1 = () => {
        getUsers();
    }

    const handleClick2 = () => {
        props.toggleView();
    }

    return (
        <div className='leaderboard-container'>
            <div className="leaderboard-title">
                <h3>Leaderboard</h3>
            </div>
            <div className="leaderboard">
                {users.map((user, index) => {
                    return(
                        <div key={index} className="user-info">
                            <span className='name'>{user.name}</span>
                            <span className='score'>{user.score}</span>
                        </div>
                    )
                })}
            </div>
            <div className="button-container">
                <button onClick={handleClick1} aria-label="Refresh leaderboard" className="btn-control">
                    <img src={BtnRefresh} alt=''></img>
                </button>
                <button onClick={handleClick2} aria-label="Show Game" className="btn-leaderboard">
                    <img src={BtnLeaderboard} alt = ''></img>
                </button>
                <button onClick={handleClick2} aria-label="Show Game" className="btn-control">
                    <img src={BtnGame} alt=''></img>
                </button>
            </div>
        </div>
    )
}