import { Redirect, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import './Landing.scss';

import Title from '../Images/manimals.png';
import Subtitle from '../Images/subtitle.png';
import Logo from '../Images/logo.png';

export default function Landing (props) {
    const history = useHistory();

    const handleClick = () => {
        history.replace(`/code`)
    }

    if(Cookies.get('show')) {
        const showId = Cookies.get('show')
        return (
            <Redirect to={`/${showId}`} />
        )
    }

    return (
        <div className='landing' onClick={handleClick}>
            <img src={Logo} alt="Manimals whale logo" className="logo"></img>
            <img src={Title} alt="Manimals" className="title"></img>
            <button onClick={handleClick} className="subtitle">
                <img src={Subtitle} alt="Hack your dating life"></img>
            </button>
            <div className='link'>
                <p>A playfully filthy online show, inspired by real-world dates.</p>
                <a href="https://michellehudsoncreative.wordpress.com/tickets-now-on-sale/">Click here for tickets</a>
            </div>
        </div>
    )
}