import React, { useEffect} from 'react';
import { Link, useParams, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';

import './Intro.scss';

import Logo from '../../Images/logo.png';

export default function Intro (props) {
    let { showId } = useParams();

    useEffect(() => {
        if(Cookies.get('username')) {
            const username = Cookies.get('username');
            props.saveUsername(username);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(Cookies.get('quiz-complete')) {
        return (
            <Redirect to={`/${showId}/game`} />
        )
    }

    return (
        <div className='intro'>
            <img src={Logo} alt="Manimals whale logo"></img>
            <h5>Welcome {props.username}, thank you for registering</h5>
            <p>Manimals makes dating fun and gets you on the fast-track to love, guaranteed!</p>
            <p><i>Manimal</i> = Any profile that has an unusual (eg not dog or cat) and alive animal in the picture.</p>
            <p>Accept (swipe/click Right) the Manimals, Reject (swipe/click Left) the non Manimals, and swipe right or left on the profile-boosting Wild Cards… but remember, if you accept a Dare card you need to complete the action in real life!</p>
            <p>Check your score anytime by clicking the Leaderboard icon in the bottom centre of the screen.</p>

            <Link to={`/${showId}/quiz`} className="btn btn-submit btn-wide">Set up your profile</Link>
        </div>
    )
}