import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";

import fire from '../../../fire';

import UserInfo from './UserInfo';

import './ShowControls.scss';

export default function ShowControls (props) {
    let [ users, setUsers ] = useState([]);
    let [ gameActive, setGameActive ] = useState([]);
    let { showId } = useParams();

    useEffect(() => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users");
        let unsubscribe = docRef.onSnapshot((querySnapshot) => {
            let list = [];
            querySnapshot.forEach((doc) => {
                let obj = {};
                obj.name = doc.data().name;
                obj.score = doc.data().score;
                obj.mc1 = doc.data().mc1;
                obj.mc2 = doc.data().mc2;
                obj.text1 = doc.data().text1;
                obj.text2 = doc.data().text2;
                list.push(obj)
            });
            list.sort((a,b) => {
                return b.name - a.name;
            })
            setUsers([...list]);
        })

        return function cleanup() {
            unsubscribe();
        };
    }, [ showId ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const docRef = fire.firestore().collection("shows").doc(showId);
        let unsubscribe = docRef.onSnapshot( (doc) => {
            setGameActive(doc.data().gameActive);
        })

        return function cleanup() {
            unsubscribe();
        };
    });

    const handleToggleClick = () => {
        const docRef = fire.firestore().collection("shows").doc(showId);
        docRef.get().then((doc) => {
            const currentState = doc.data().gameActive;
            doc.ref.update({gameActive: !currentState})
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            alert('Error setting game state: ', error)
            console.error('Error setting game state: ', error);
        });
    }

    const adjustScore = (user, scoreAdjustment) => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(user);
        docRef.get().then((doc) => {
            const currentScore = doc.data().score;
            doc.ref.update({score: currentScore + scoreAdjustment})
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            alert('Error setting score: ', error)
            console.error('Error setting score: ', error);
        });
    }

    const deleteUser = (user) => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(user);
        docRef.delete().then(() => {
            alert('User deleted')
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            alert('Error deleting user: ', error)
            console.error('Error deleting user: ', error);
        });
    }

    return (
        <div className="controls-show">
            <h1>Selected Show: {showId}</h1>
            <h2>{gameActive ? 'Game Is Active' : 'Game Is Not Active'}</h2>
            <button onClick={handleToggleClick} className="show-active-toggle">{gameActive ? 'Deactivate' : 'Activate'}</button>
            <h2>Leaderboard</h2>
            <div className="list-users">
                {users.map((user, index) => {
                    return(
                        <UserInfo
                            key={index}
                            adjustScore={adjustScore}
                            deleteUser={deleteUser}
                            name={user.name}
                            score={user.score}
                            mc1={user.mc1}
                            mc2={user.mc2}
                            text1={user.text1}
                            text2={user.text2}
                        />
                    )
                })}
            </div>
        </div>
    )
}