import React, { useState } from 'react';
import { useHistory } from 'react-router';
import fire from '../fire';

export default function Login (props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        if (name === 'email') {
            setEmail(value);
        } else {
            setPassword(value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        fire.auth().signInWithEmailAndPassword(email, password)
        .then((user) => {
            history.replace('/backstage')
        })
        .catch((error) => {
            console.log(error)
        });
    }
    
    return (
        <div>
            <p>Login</p>
            <form onSubmit={handleSubmit}>
                <label>
                    email:
                    <input 
                        type="email" 
                        name="email"
                        value={email}
                        onChange={handleInputChange}/>
                </label>
                <label>
                    password:
                    <input 
                        type="password" 
                        name="password"
                        value={password}
                        onChange={handleInputChange}/>
                </label>
                <input type="submit" value="Submit"></input>
            </form>
        </div>
    )
}