import React from 'react';

import { useParams } from "react-router-dom";

import './Card.scss';

export default function Card (props) {
    let { showId } = useParams();

    const handleLoad = () => {
        if(props.next) return;
        props.onFirstForegroundImageLoad();
    }

    const getImg = () => {
        if(!props.data) {return ''}
        let currentImg = props.data[props.activeId];

        if(!currentImg) {return ''}
        let imgKey = currentImg.name;

        if(showId === "NOSHOW" && currentImg.soloAlt) {
            imgKey = currentImg.soloAlt;
        }

        if (!props.images[imgKey]) {return ''}
        let imgSrc = props.images[imgKey].default;
        if (!imgSrc) {return ''}

        return <img src={imgSrc} alt="" onLoad={handleLoad}></img>
    }

    return (
        <div className="card">
            {getImg()}
        </div>
    )
}