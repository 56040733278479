import { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import fire from '../fire';

import './CodeEntry.scss';

import Logo from '../Images/logo.png';

export default function Landing (props) {
    const [showId, setShowId] = useState('access code');
    const [error, setError] = useState('');
    const history = useHistory();

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
    
        setShowId(value);
    }

    const handleInputClick = (event) => {
        setShowId('');
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const nameUppercase = showId.toUpperCase();

        const docRef = fire.firestore().collection("shows").doc(nameUppercase);
        await docRef.get().then((doc) => {
            if (doc.exists) {
                trySetCookie()
                history.replace(`/${nameUppercase}`)
            } else {
                setError('no such code!');
            }
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const trySetCookie = () => {
        const enabled = Cookies.get('cookies-enabled');
        if (enabled) {
            Cookies.set('show', showId.toUpperCase(), { expires: 7 });
        }
    }

    if(Cookies.get('show')) {
        const showId = Cookies.get('show')
        return (
            <Redirect to={`/${showId}`} />
        )
    }

    const getBtnClass = () => {
        let className = 'btn btn-wide';

        if(showId === '' || showId === 'access code') {
            className += ' btn-inactive'
        } else {
            className += ' btn-submit'
        }

        return className;
    }

    return (
        <div className='code-entry'>
            <img src={Logo} alt="Manimals whale logo"></img>
            <p>Audience members enter your access code here:</p>
            {error}
            <form onSubmit={handleSubmit} className='form-text'>
                <input 
                    type="name" 
                    name="showId"
                    value={showId}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    className={showId === 'access code' ? 'input-text input-text-invalid' : 'input-text'}/>
                <input type="submit" value="Submit" className={getBtnClass()}></input>
            </form>
            <p className='note'>If you aren't accessing this app as part of a scheduled virtual performance, enter code "NoShow" to access the game.</p>
            <Link to='login' className='manager-link'>Manager Login</Link>
        </div>
    )
}