import React, { useState, useEffect } from 'react';
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";

import ShowControls from '../ShowControls/ShowControls';
import SimpleLeaderboard from '../SimpleLeaderboard/SimpleLeaderboard';

import fire from '../../../fire';

import GameData from '../../../GameData/data.json';

import './ShowList.scss';

export default function ShowList (props) {
    const [showList, setShowList] = useState([]);
    const [name, setName] = useState([]);
    let match = useRouteMatch();

    useEffect(() => {
        getShowList();
    }, []);

    const getShowList = async () => {
        let list = [];
        await fire.firestore().collection("shows").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let obj = {};
                obj.name = doc.id;
                obj.showInProgress = doc.data().showInProgress;
                list.push(obj)
            });
        })
        setShowList([...list]);
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        setName(value)
    }

    const createOrder = (arr) => {
        let newArr = [];
        for (let i=0; i<arr.length; i++){
            newArr.push(arr[i].number)
        }
        return newArr
    }

    const checkShowNameWithFirebase = async (str) => {
        const docRef = fire.firestore().collection("shows").doc(str);
        const nameTaken = await docRef.get().then((doc) => {
            if (doc.exists) {
                return true
            } else {
                return false
            }
        })
        .catch((err) => {
            console.log(err)
        })

        return nameTaken;
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        const nameToSubmit = name.toUpperCase();

        const nameTaken = await checkShowNameWithFirebase(nameToSubmit);
        if (nameTaken) {
            alert('that name is already in use');
            return;
        }

        const order = createOrder(GameData.photos)

        fire.firestore().collection("shows").doc(nameToSubmit).set({
            showInProgress: true,
            gameActive: false,
            questionOrder: order
        })
        .then(function() {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    return (
        <div className="show-list">
            <div className="admin">
                <div className="show-new">
                    <h2>Create new show</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            name:
                            <input 
                                type="name" 
                                name="name"
                                value={name}
                                onChange={handleInputChange}/>
                        </label>
                        <input type="submit" value="Submit"></input>
                    </form>
                </div>
                <div className="show-select">
                    <h2>Shows:</h2>
                    <button onClick={getShowList}>Refresh</button>
                    <div className="scrollable">
                        {showList.map((show, index) => {
                            return (
                                <div key={index}>
                                    <span>{show.name} --- </span>
                                    <Link to={`${match.url}/${show.name}`}>Full dashboard</Link>
                                    <span> --- </span>
                                    <Link to={`${match.url}/${show.name}/simple`}>Simple dashboard</Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            </div>
            <hr></hr>
            <Switch>
                <Route path={`${match.path}/:showId/simple`}>
                    <SimpleLeaderboard />
                </Route>
                <Route path={`${match.path}/:showId`}>
                    <ShowControls />
                </Route>
                <Route path={match.path}>
                    <h3>Please select a show.</h3>
                </Route>
            </Switch>
            <hr></hr>
        </div>
    )
}