import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyDq_qMIBXvwhqYMqpcT1Pfqo_3M8w2OHqc",
    authDomain: "manimals-a6e55.firebaseapp.com",
    databaseURL: "https://manimals-a6e55-default-rtdb.firebaseio.com",
    projectId: "manimals-a6e55",
    storageBucket: "manimals-a6e55.appspot.com",
    messagingSenderId: "1092740531473",
    appId: "1:1092740531473:web:725882358555d4cae7b8b0",
    measurementId: "G-RTCGYP9883"
};
var fire = firebase.initializeApp(config);
export default fire;