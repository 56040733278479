import React, { useState } from 'react';

import ScoreAdjust from './ScoreAdjust';

import './UserInfo.scss';

export default function UserInfo (props) {
    let [ expanded, setExpanded ] = useState(false);

    const handleClick = () => {
        setExpanded(!expanded)
    }

    const getAnswers = () => {
        if (!expanded) {
            return ''
        } else {
            return (
                <div className="answers">
                    <p>goal: <b>{props.mc1}</b></p>
                    <p>text question #1: <b>{props.text1}</b></p>
                    <p>text question #2: <b>{props.text2}</b></p>
                </div>
            )
        }
    }

    return (
        <div className="user-info-back">
            <div className="summary">
                <span className="name">NAME: {props.name}</span>
                <ScoreAdjust
                    name={props.name} 
                    score={props.score}
                    adjustScore={props.adjustScore}
                    deleteUser={props.deleteUser}
                />
                <button onClick={handleClick}>Show Quiz Answers</button>
            </div>
            {getAnswers()}
        </div>
    )
}