import React from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Cookies from 'js-cookie';
import Modal from 'react-modal';

import Landing from './Components/Landing';
import CodeEntry from './Components/CodeEntry';
import Login from './Components/Login';
import Backstage from './Components/Backstage/Backstage';
import AudienceView from './Components/AudienceView/AudienceView'

import './App.scss';
import './Styles/Modal.scss';
import './Styles/Forms.scss';

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: null,
      modalOpen: false,
      acceptCookies: false
    }
  }

  componentDidMount () {
    Modal.setAppElement('#root');
    this.checkCookies();
  }

  checkCookies = () => {
    //Cookies.set('cookies enabled', 'true')
    const enabled = Cookies.get('cookies-enabled');
    if(enabled === undefined) {
      this.openModal();
    }
  }

  saveUsername = (str) => {
    this.setState({username: str})
  }

  openModal = () => {
    this.setState({modalOpen: true})
  }

  closeModal = () => {
    this.setState({modalOpen: false})
  }

  acceptCookies = (accept) => {
    this.setState({
      acceptCookies: accept,
      modalOpen: false
    })
    Cookies.set('cookies-enabled', true);
  }

  render () {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              <Landing></Landing>
            </Route>
            <Route path="/code">
              <CodeEntry></CodeEntry>
            </Route>
            <Route path="/login">
              <Login></Login>
            </Route>
            <Route path="/backstage">
              <Backstage></Backstage>
            </Route>
            <Route path="/:showId">
              <AudienceView
                username={this.state.username}
                saveUsername={this.saveUsername}
              />
            </Route>
          </Switch>
        </BrowserRouter>
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Cookie Agreement"
          overlayClassName="overlay"
          className="modal"
        >
          <h4>PLEASE READ</h4>
          <p>This app requires cookies to save your user name and score.</p>
          <p>If you have not accepted cookies, and you close this browser window after setting up your profile, you will need to set up a new profile with a different name.</p>
          <p>Do you accept?</p>
          <div className='btn-container'>
            <button onClick={() => this.acceptCookies(false)} className="btn btn-white">Decline</button>
            <button onClick={() => this.acceptCookies(true)} className="btn btn-teal">Accept</button>
          </div>
        </Modal>
      </div>
    );
  }
}