import React, { useState, useEffect } from 'react';
import {
    useParams,
    useHistory,
    Redirect
} from "react-router-dom";
import Cookies from 'js-cookie';

import fire from '../../fire';

import './Quiz.scss';

export default function Quiz (props) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [mc1, setMc1] = useState('')
    const [text1, setText1] = useState('animal');
    const [text2, setText2] = useState('thing');
    const [error, setError] = useState([])
    let history = useHistory();
    let { showId } = useParams();

    useEffect(() => {
        if(Cookies.get('username')) {
            const username = Cookies.get('username');
            props.saveUsername(username);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (event) => {
        const target = event.target;
        
        if (target.name === "mc1") {
            setMc1(target.value)
        } else if (target.name === "text1") {
            setText1(target.value)
        } else {
            setText2(target.value)
        }
    }

    const calculateScore = () => {
        let score = 0;
        // add 25 for both mc questions no matter the answer
        if (mc1 !== '') {
            score += 25;
        }
        if (text1.length >= 3) {
            score += 50;
        }
        if (text2.length >= 20) {
            score += 50;
        }
        return score;
    }

    const setQuizDataWithFirebase = async (score) => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(props.username);
        const result = await docRef.set({
            name: props.username,
            score: score,
            mc1: mc1,
            text1: text1,
            text2: text2,
            created: Date.now()
        })
        .then(() => {
            console.log("Document successfully written!");
            return true;
        })
        .catch((err) => {
            console.error("Error writing document: ", err);
            return false;
        })

        return result;
    }

    const handleSubmit = async (event) => {
        const score = calculateScore();
        
        const record = await setQuizDataWithFirebase(score);
        if (!record) {
            setError("error recording your results, please try again")
            return
        } else {
            trySetCookie();
            history.replace(`/${showId}/game`);
        }
    }

    const trySetCookie = () => {
        const enabled = Cookies.get('cookies-enabled');
        if (enabled) {
            Cookies.set('quiz-complete', true, { expires: 7 });
        }
    }

    if(Cookies.get('quiz-complete')) {
        return (
            <Redirect to={`/${showId}/game`} />
        )
    }

    const handleInputClick = (id) => {
        if (id === 1){
            setText1('');
        } else {
            setText2('');
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            next();
        }
    }

    const getQuestion = () => {
        if (activeSlide === 0) {
            return (
                <div className='q-mc'>
                    <h5 className='question'>Are you looking for:</h5>
                    <button type="button" onClick={() => setMc1('Sex')} className={mc1 === 'Sex' ? 'btn btn-wide btn-pink' : 'btn btn-wide btn-white btn-border-teal'}>Sex</button>
                    <button type="button" onClick={() => setMc1('Companionship')} className={mc1 === 'Companionship' ? 'btn btn-wide btn-pink' : 'btn btn-wide btn-white btn-border-teal'}>Companionship</button>
                    <button type="button" onClick={() => setMc1('The One')} className={mc1 === 'The One' ? 'btn btn-wide btn-pink' : 'btn btn-wide btn-white btn-border-teal'}>The One</button>
                </div>
            )
        } else if (activeSlide === 1) {
            return (
                <div className='form-text'>
                    <h5 className='question'>What animal would you most like to swipe right on?</h5>
                    <p>(Swipe right = Like/Accept)</p>
                    <p>Dogs & cats are too common... think outside the box!</p>
                    <input 
                        type="text" 
                        name="text1"
                        value={text1}
                        onKeyDown={handleKeyDown}
                        onChange={handleInputChange}
                        onClick={() => handleInputClick(1)}
                        className={text1 === 'animal' ? 'input-text input-text-invalid' : 'input-text'}/>
                </div>
            )
        } else if (activeSlide === 2) {
            return (
                <div className='form-text'>
                    <h5 className='question'>What is the number one thing that makes you swipe left on a dating profile?</h5>
                    <p>(Swipe left = Dislike/Reject)</p>
                    <input 
                        type="text" 
                        name="text2"
                        value={text2}
                        onKeyDown={handleKeyDown}
                        onChange={handleInputChange}
                        onClick={() => handleInputClick(2)}
                        className={text2 === 'thing' ? 'input-text input-text-invalid' : 'input-text'}/>
                </div>
            )
        }    
    }

    const next = () => {
        if (activeSlide === 0 || activeSlide === 1) {
            setActiveSlide(activeSlide+1)
        } else {
            handleSubmit();
        }
    }

    const getNextClass = () => {
        if (activeSlide === 0) {
            if (mc1 === '') {
                return "btn btn-inactive"
            } else {
                return "btn btn-teal"
            }
        } else if (activeSlide === 1) {
            if (text1 === 'animal' || text1 === '') {
                return "btn btn-inactive"
            } else {
                return "btn btn-teal"
            }
        } else if (activeSlide === 2) {
            if (text2 === 'thing' || text2 === '') {
                return "btn btn-inactive"
            } else {
                return "btn btn-teal"
            }
        }
    }

    const back = () => {
        if (activeSlide === 2 || activeSlide === 1) {
            setActiveSlide(activeSlide-1)
        }
    }

    const getBackClass = () => {
        if (activeSlide === 0) {
            return 'btn transparent'
        } else {
            return 'btn'
        }
    }

    return (
        <div className='quiz'>
            <h5>Set Up your Profile</h5>
            <form>
                {getQuestion()}
                <div className={'indicator-container'}>
                    <span className={activeSlide === 0 ? 'indicator indicator-active' : 'indicator'}>&#9679;</span>
                    <span className={activeSlide === 1 ? 'indicator indicator-active' : 'indicator'}>&#9679;</span>
                    <span className={activeSlide === 2 ? 'indicator indicator-active' : 'indicator'}>&#9679;</span>
                </div>
                <div className="btn-container">
                    <button onClick={back} type="button" className={getBackClass()}>Back</button>
                    <button onClick={next} type="button" className={getNextClass()}>Next</button>
                </div>
                <div>
                    {error}
                </div>
            </form>
        </div>
    )
}