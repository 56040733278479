import React from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';

import fire from '../../fire';

import ShowList from './ShowList/ShowList';

import './Backstage.scss';

export default function Backstage (props) {
    const history = useHistory();

    const handleClick = () => {
        fire.auth().signOut().then(function() {
            console.log('Signed Out');
            history.replace('/')
        }, function(error) {
            console.error('Sign Out Error', error);
        });
    }

    if (!fire.auth().currentUser) {
        return <Redirect to='/'/>
    }

    return (
        <div className="backstage">
            <h1>Backstage</h1>
            <button onClick={handleClick}>Sign out</button>
            <ShowList></ShowList>
        </div>
    )
}