import React, { useState, useEffect } from 'react';
import {
    useRouteMatch,
    useParams,
    useHistory
} from "react-router-dom";
import Filter from 'bad-words';
import Cookies from 'js-cookie';

import fire from '../../fire';

import './ChooseName.scss';

import Logo from '../../Images/logo.png';

export default function AudienceView (props) {
    const [error, setError] = useState([]);
    const [username, setUsername] = useState('name');
    let history = useHistory();
    let match = useRouteMatch();
    let { showId } = useParams();

    useEffect(() => {
        if(Cookies.get('username')) {
            const username = Cookies.get('username');
            props.saveUsername(username);
            history.replace(`${match.url}/intro`);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const longEnoughUsername = async (str) => {
        if (str.length <= 0) {
            return false;
        } else {
            return true;
        }
    }

    const validateUsername = async (str) => {
        const filter = new Filter();
        if (filter.isProfane(str)) {
            return false;
        } else {
            return true;
        }
    }

    const checkUsernameWithFirebase = async (str) => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(str);
        const nameTaken = await docRef.get().then((doc) => {
            if (doc.exists) {
                return true
            } else {
                return false
            }
        })
        .catch((err) => {
            console.log(err)
        })

        return nameTaken;
    }

    const setUsernameWithFirebase = async (str) => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(str);
        const success = await docRef.set({
            name: str,
            score: 0
        })
        .then(() => {
            console.log("Document successfully written!");
            return true;
        })
        .catch((err) => {
            console.error("Error writing document: ", err);
            return false;
        })

        return success;
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        setUsername(value)
    }

    const handleInputClick = () => {
        setUsername('')
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const valid = await validateUsername(username);
        if (!valid) {
            setError('name rejected for profanity');
            return;
        }

        const longEnough = await longEnoughUsername(username);
        if (!longEnough) {
            setError('please enter a name');
            return;
        }

        const taken = await checkUsernameWithFirebase(username);
        if (taken) {
            setError('that name is taken');
            return;
        }

        const setUser = await setUsernameWithFirebase(username);
        if (!setUser) {
            setError('error creating your profile, please try again');
            return;
        } else {
            props.saveUsername(username);
            trySetCookie();
            history.replace(`${match.url}/intro`);
        }
    }

    const trySetCookie = () => {
        const enabled = Cookies.get('cookies-enabled');
        if (enabled) {
            Cookies.set('username', username, { expires: 7 });
        }
    }

    const getBtnClass = () => {
        let className = 'btn btn-wide';

        if(username === '' || username === 'name') {
            className += ' btn-inactive'
        } else {
            className += ' btn-submit'
        }

        return className;
    }

    const getNote = () => {
        if (showId !== "NOSHOW") {return ''}
        return (
            <p className='instruction'>If you have a Twitter handle, use that as your Dating Profile Name to win more points</p>
        )
    }

    return (
        <div className="form-name">
            <img src={Logo} alt="Manimals whale logo"></img>
            <p>Choose your dating profile name:</p>
            {error}
            {getNote()}
            <form onSubmit={handleSubmit} className='form-text'>
                <input 
                    type="username" 
                    name="username"
                    value={username}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    className={username === 'name' ? 'input-text input-text-invalid' : 'input-text'}/>
                <input type="submit" value="Submit" className={getBtnClass()}></input>
            </form>
        </div>
    )
}