import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";

import fire from '../../../fire';

import './SimpleLeaderboard.scss';

export default function Leaderboard (props) {
    let [ users, setUsers ] = useState([]);
    let [ fullscreen, setFullscreen ] = useState(false);
    let { showId } = useParams();

    useEffect(() => {
        const docRef = fire.firestore().collection("shows").doc(showId).collection("users");
        let unsubscribe = docRef.onSnapshot((querySnapshot) => {
            let list = [];
            querySnapshot.forEach((doc) => {
                let obj = {};
                obj.name = doc.data().name;
                obj.score = doc.data().score;
                list.push(obj)
            });
            list.sort((a,b) => {
                return b.score - a.score;
            })
            setUsers([...list]);
        })

        return function cleanup() {
            unsubscribe();
        };
    }, [ showId ]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleFullscreen = () => {
        setFullscreen(!fullscreen)
    }

    const getClassname = () => {
        if(fullscreen) {
            return "leaderboard leaderboard-fullscreen"
        } else {
            return "leaderboard"
        }
    }

    return (
        <div className='simple-leaderboard-container'>
            <div className={getClassname()}>
                <h3 className="leaderboard-title" onClick={toggleFullscreen}>Leaderboard</h3>
                <div className="user-container">
                    {users.map((user, index) => {
                        return(
                            <div key={index} className="user-info">
                                <span className='name'>{user.name}</span>
                                <span className='score'>{user.score}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}