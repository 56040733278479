import React from 'react';
import { withRouter } from "react-router-dom";
import Cookies from 'js-cookie';

import Swipe from './Swipe';
import Leaderboard from './Leaderboard';

import fire from '../../../fire';

import gameData from '../../../GameData/data.json';

//Import Helper
function importAll(r) {
    let assets = {};
    // eslint-disable-next-line
    r.keys().map((item) => { 
      assets[item.replace('./', '')] = r(item);
    });
    return assets;
  }

class Game extends React.Component {
    constructor(props) {
        super (props) 

        this.state = {
            leaderboardTab: true,
            activeId: 0
        }
    }

    componentDidMount () {
        this.checkCookies();
        this.setupImages();
        this.setupData();
    }

    checkCookies = () => {
        if(Cookies.get('username')) {
            const username = Cookies.get('username');
            this.props.saveUsername(username);
        } else {
            this.props.history.replace(`/`);
        }

        if(Cookies.get('current-question-id')) {
            const id = Cookies.get('current-question-id');
            const idNum = parseInt(id)
            this.setState({activeId: idNum})
        }
    }

    trySetCookie = () => {
        const enabled = Cookies.get('cookies-enabled');
        if (enabled) {
            Cookies.set('current-question-id', this.state.activeId, { expires: 7 });
        }
    }

    setupImages = () => {
        let images = importAll(require.context("../../../GameData/Photos", false, /\.(jpg|png|gif)$/));
        this.setState({images: images});
    }

    setupData = async () => {
        const docRef = fire.firestore().collection("shows").doc(this.props.match.params.showId);
        await docRef.get().then((doc) => {
            const order = doc.data().questionOrder;
            let arr = gameData.photos.sort(function (a, b) {
                return order.indexOf(a.number) - order.indexOf(b.number);
            });
            this.setState({data: arr})
        })
    }

    nextCard = () => {
        if (this.state.data.length < this.state.activeId) {
            return;
        }
        this.setState({activeId: this.state.activeId + 1}, () =>{
            this.trySetCookie()
        })
    }

    toggleView = () => {
        this.setState({leaderboardTab: !this.state.leaderboardTab})
    }

    render () {
        if (this.state.leaderboardTab) {
            return (
                <Swipe
                    username={this.props.username}
                    toggleView={this.toggleView}
                    activeId={this.state.activeId}
                    images={this.state.images}
                    data={this.state.data}
                    nextCard={this.nextCard}
                />
            )
        } else {
            return (
                <Leaderboard
                    toggleView={this.toggleView}
                />
            )
        }
        
    }
}

export default withRouter (Game);