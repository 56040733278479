import React, { useState } from 'react';

import './ScoreAdjust.scss';

export default function UserInfo (props) {
    let [ scoreAdjustment, setScoreAdjustment ] = useState(0);

    const handleScoreClick = (adjustment) => {
        props.adjustScore(props.name, adjustment)
    }


    const handleSubmit = async(event) => {
        event.preventDefault();

        let num = parseInt(scoreAdjustment)
        if(isNaN(num) || typeof num !== 'number') {
            num = 0;
        }

        props.adjustScore(props.name, num)
        setScoreAdjustment(0)
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        setScoreAdjustment(value)
    }

    const handleDeleteClick = () => {
        if(window.confirm("Confirm delete")) {
            props.deleteUser(props.name);
        }
    }

    return (
        <span className="score">
            <span className="score-score">SCORE: {props.score}</span>
            <div className="buttons">
                <button onClick={() => handleScoreClick(-50)}>-50</button>
                <button onClick={() => handleScoreClick(-25)}>-25</button>
                <button onClick={() => handleScoreClick(25)}>+25</button>
                <button onClick={() => handleScoreClick(50)}>+50</button>
                <button onClick={() => handleScoreClick(300)}>+300</button>
            </div>
            <form onSubmit={handleSubmit} className='custom'>
                <label>
                    custom:
                    <input 
                        className="input-score"
                        type="number" 
                        name="scoreAdjustment"
                        value={scoreAdjustment}
                        onChange={handleInputChange}/>
                </label>
                <input type="submit" value="add/subtract"></input>
            </form>
            <button onClick={handleDeleteClick} className="btn-delete">Delete</button>
        </span>
    )
}