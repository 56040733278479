import React,  { useState, useEffect, useRef } from 'react';
import {
    useParams
} from "react-router-dom";
import TinderCard from 'react-tinder-card'

import Card from './Card';
import Feedback from './Feedback';

import fire from '../../../fire';

import './Swipe.scss';

import BtnLeaderboard from '../../../Images/Icons/btn_leaderboard.png';
import BtnYes from '../../../Images/Icons/btn_y.png';
import BtnNo from '../../../Images/Icons/btn_n.png';
import Logo from '../../../Images/logo.png';

export default function Swipe (props) {
    let [ gameActive, setGameActive ] = useState(false);
    let [ showFeedback, setShowFeedback] = useState(false);
    let [ lastDirection, setLastDirection] = useState();
    let [ swipeCorrect, setSwipeCorrect] = useState(true);
    let [ firstImageLoaded, setFirstImageLoaded] = useState(false);
    let { showId } = useParams();
    let currentCardRef = useRef(null)

    useEffect(() => {
        const docRef = fire.firestore().collection("shows").doc(showId);
        let unsubscribe = docRef.onSnapshot( (doc) => {
            setGameActive(doc.data().gameActive);
        })

        return function cleanup() {
            unsubscribe();
        };
    });

    const handleToggleClick = () => {
        props.toggleView();
    }

    const handleFeedback = async() => {
        setShowFeedback(true);

        const hideFeedback = new Promise((resolve, reject) => {
            setTimeout(() => {
                setShowFeedback(false)
                resolve('success')
            }, 1200)
        })

        await hideFeedback.then(() => {
            props.nextCard();
            return('feedback now hidden')
        })
    }

    const swipeDirectionWasCorrect = (rightSwipe, card) => {
        const currentCard = card;

        let answerCorrect = false;

        if (rightSwipe && currentCard.rightSwipeCorrect) {
            answerCorrect = true;
        }
        if (!rightSwipe && currentCard.leftSwipeCorrect) {
            answerCorrect = true;
        }
        if (currentCard.leftSwipeCorrect && currentCard.rightSwipeCorrect) {
            answerCorrect = true;
        }

        return answerCorrect;
    }

    const handleClick = async (rightSwipe, fromSwipeNotButton) => {
        //The default game should not include the last question
        let targetLength = props.data.length - 1;
        //If we are in the solo show, there is one more dare to show
        if ( showId === "NOSHOW") {
            targetLength ++;
        }
        if (targetLength <= props.activeId) {
            return;
        } 

        // the swipe library has a weird interaction which causes activeId to be reduced by one when using the swipe functionality and not the button
        // so, if we identify this function as having come from a swipe, we add one to the active id
        // if it is a button press, use the default
        let id = props.activeId;
        if (fromSwipeNotButton) {
            id += 1;
        }
        const currentCard = props.data[id]
        
        const answerCorrect = swipeDirectionWasCorrect(rightSwipe, currentCard);
        setSwipeCorrect(answerCorrect);

        handleFeedback();
        if (answerCorrect) {
            const docRef = fire.firestore().collection("shows").doc(showId).collection("users").doc(props.username);
            const score = await docRef.get().then((doc) => {
                return doc.data().score;
            })
            await docRef.update({
                score: score + currentCard.score
            })
        }
    }

    const warning = () => {
        //The default game should not include the last question
        let targetLength = props.data.length - 1;
        //If we are in the solo show, there is one more dare to show
        if ( showId === "NOSHOW") {
            targetLength ++;
        }

        if (targetLength <= props.activeId) {
            return <div>
                <h4>No more Manimals to load. You've reached the end. Look around you. It's over.</h4>
                <h4>Head over to <a href='https://twitter.com/manimalsplay'>@ManimalsPlay</a> on Twitter to see other player responses. Thanks for playing!</h4>
            </div>
        } else {
            return '';
        }
    }

    const getSwipeContainerClass = () => {
        let targetLength = props.data.length - 1;
        if ( showId === "NOSHOW") {
            targetLength ++;
        }
        if (targetLength <= props.activeId) {
            return 'swipe-container swipe-container-warning'
        } else {
            return 'swipe-container';
        }
    }

    const getButtonClass = (str) => {
        let targetLength = props.data.length - 1;
        if ( showId === "NOSHOW") {
            targetLength ++;
        }
        if (targetLength <= props.activeId) {
            str +=  " hidden";
        }
        return str
    }

    const swiped = (direction, fromSwipeNotButton) => {
        let dir = direction;
        if(dir === 'up') {
            dir = 'right'
        }
        if(dir === 'down') {
            dir = 'left'
        }
        setLastDirection(dir)
        if (dir === 'right') {
            handleClick(true, fromSwipeNotButton)
        } else {
            handleClick(false, fromSwipeNotButton)
        }
    }
    
    const onSwipe = (dir) => {
        currentCardRef.current.swipe(dir)
    }

    const onFirstForegroundImageLoad = () => {
        //We just need to hide the background card until the first foreground card has loaded so they don't show in the wrong order
        //So this only has to go off once
        setFirstImageLoaded(true);
    }

    const backgroundCard = () => {
        //Only solo shows should show the last card
        let targetLength = props.data.length - 1;
        if (showId !== "NOSHOW") {
            targetLength -= 1;
        }

        if (props.activeId >= targetLength) {
            return '';
        }

        if (!firstImageLoaded) {
            return '';
        }
        
        return (
            <TinderCard 
                className='swipe' 
                key={props.data[props.activeId + 1].name}
                onSwipe={(dir) => swiped(dir, true)}>
                <Card
                    activeId={props.activeId + 1}
                    data={props.data}
                    images={props.images}
                    next={true}/>
            </TinderCard>
        )
    }

    const mainCard = () => {
        //Only solo shows should show the last card
        let targetLength = props.data.length;
        if (showId !== "NOSHOW") {
            targetLength -= 1;
        }

        if (props.activeId >= targetLength) {
            return '';
        }

        return (
            <TinderCard 
                className='swipe'
                ref={currentCardRef} 
                key={props.data[props.activeId].name} 
                onSwipe={(dir) => swiped(dir)}>
                <Card
                    activeId={props.activeId}
                    data={props.data}
                    images={props.images}
                    next={false}
                    onFirstForegroundImageLoad={onFirstForegroundImageLoad}/>
            </TinderCard>
        )
    }

    if(gameActive) {
        return (
            <div className={getSwipeContainerClass()}>
                <div className='card-container'>
                    {warning()}
                    {backgroundCard()}
                    {mainCard()}
                </div>
                <div className="button-container">
                    <button onClick={() => onSwipe('left')} aria-label="Reject" className={getButtonClass("btn-choice btn-reject")}>
                        <img src={BtnNo} alt=''></img>
                    </button>
                    <button onClick={handleToggleClick} aria-label="Show Leaderboard" className="btn-leaderboard">
                        <img src={BtnLeaderboard} alt = ''></img>
                    </button>
                    <button onClick={() => onSwipe('right')} aria-label="Accept" className={getButtonClass("btn-choice btn-accept")}>
                        <img src={BtnYes} alt=''></img>
                    </button>
                </div>
                <Feedback
                    activeId={props.activeId}
                    data={props.data}
                    lastDirection={lastDirection}
                    showFeedback={showFeedback}
                    swipeCorrect={swipeCorrect}/>
            </div>
        )
    } else {
        return (
            <div className="waiting-container">
                <img src={Logo} alt='Manimals Whale Logo'></img>
                <h4>Wait</h4>
                <p>Your profile is set up. Come back when the game has been activated.</p>
            </div>
        )
    }
}