import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import ChooseName from './ChooseName';
import Intro from './Intro';
import Quiz from './Quiz';
import Game from './Game/Game';

import './AudienceView.scss';

export default function AudienceView (props) {
    let match = useRouteMatch();

    return (
        <div className='audience'>
            <Switch>
                <Route path={match.path} exact>
                    <ChooseName
                        saveUsername={props.saveUsername}
                    />
                </Route>
                <Route path={`${match.path}/intro`}>
                    <Intro 
                        username={props.username}
                        saveUsername={props.saveUsername}
                    />
                </Route>
                <Route path={`${match.path}/quiz`}>
                    <Quiz 
                        username={props.username}
                        saveUsername={props.saveUsername}
                    />
                </Route>
                <Route path={`${match.path}/game`}>
                    <Game 
                        username={props.username}
                        saveUsername={props.saveUsername}
                    />
                </Route>
            </Switch>
        </div>
    )
}