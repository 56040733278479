import React from 'react';

import './Feedback.scss';

import Check from '../../../Images/Icons/icon_check.png';
import X from '../../../Images/Icons/icon_x.png';

export default function Swipe (props) {

    const answerCorrect = () => {
        return props.swipeCorrect;
    }

    const getClassname= () => {
        let className = "feedback-container";

        if(answerCorrect()) {
            className += " feedback-container-correct"
        } else {
            className += " feedback-container-incorrect"
        }
        return className;
    }

    const getTextClass = () => {
        if(answerCorrect()) {
            return 'feedback-text feedback-text-correct';
        } else {
            return 'feedback-text feedback-text-incorrect';
        }
    }

    const getText = () => {
        if(props.lastDirection === "left") {
            return props.data[props.activeId].feedbackLeft || 'Rejected!';
        } else {
            return props.data[props.activeId].feedbackRight || "That's a match!";
        }
    }

    const getImgSrc = () => {
        if (answerCorrect()) {
            return Check;
        } else {
            return X;
        }
    }

    const getImgAlt = () => {
        if (answerCorrect()) {
            return 'Correct';
        } else {
            return 'Wrong';
        }
    }

    const getPoints = () => {
        if(answerCorrect()) {
            return "+" + props.data[props.activeId].score + " pts";
        } else {
            return '0 pts';
        }
    }

    if(props.showFeedback) {
        return (
            <div className={getClassname()}>
                <div className="feedback-background"></div>
                <div className={getTextClass()}>
                    <p>{getText()}</p>
                </div>
                <div className="feedback-img">
                    <img src={getImgSrc()} alt={getImgAlt()}></img>
                </div>
                <div className="feedback-points">{getPoints()}</div>
            </div>
        )
    } else {
        return ''
    }
}